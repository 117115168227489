import React, {useState, useEffect} from "react";
import { auth, firedb } from "../../firebase";
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';

const Home = (props) => {

    const [hpData,setHpData]=useState([])
    const fetchHp=async()=>{
        const response=firedb.collection('homePage');
        const data=await response.get();
        data.docs.forEach(item=>{
         setHpData([...hpData,item.data()])
        })
      }
    
      useEffect(() => {
        fetchHp();
      }, [])
    
  return (
    <div>
      <div className="text-center p-5">
        <img src={process.env.PUBLIC_URL +"/img/main-logo.png"} alt="site-logo" width="220px" />
        <p className="my-3">{props.user.email}</p>
        <button className="btn btn-danger" onClick = {() => {auth.signOut()}}>Logout</button>  
      </div>
    <section className="video-section bg1 py-4">
        <div className="container-fluid">
            <div className="mb-3">
                <h1 className="text-white">Welcome To Your Virtual Work Experience</h1>
            </div>
            <div className="row">
            <div className="col-md-9">
                <div className="embed-responsive embed-responsive-16by9">
                    <iframe className="embed-responsive-item" title="youtube video" width="1250" height="703" src={hpData[0]!==undefined ? hpData[0].videoCode : ''} frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                </div>
            </div>
            <div className="col-md-3">
                <div className="">
                    <iframe src={ hpData[0]!==undefined ? hpData[0].slidoCode : '' } title="sildo" height="100%" width="100%" frameBorder="0" style={{minHeight: 560+'px'}}></iframe>
                </div>
            </div>
            </div>
            <div className="col-md-12 py-3 text-center">
                <a href={ hpData[0]!==undefined ? hpData[0].videoCode2 : '' } target="blank" className="text-white h5">Trouble viewing the stream? Please click here</a>
            </div>
        </div>
    </section>
    <section className="wex-resources py-5 bg2">
        <div className="container">
            <div className="row">
                <div className="col-md-4">
                    <h3 className="heading-title">{(hpData[0]!==undefined && hpData[0].attendanceTitle!==undefined) ? hpData[0].attendanceTitle : '' }</h3>
                    <p className="mt-3">{(hpData[0]!==undefined && hpData[0].attendanceDesc!==undefined) ? hpData[0].attendanceDesc : '' }</p>
                </div>
                <div className="col-md-8">
                    <div className="img-wrapper">
                        <img src={(hpData[0]!==undefined && hpData[0].attendanceImg!==undefined) ? hpData[0].attendanceImg : '' } alt="" className="img-fluid" />
                    </div>
                    <div className="resource-title text-center p-5 bg3">
                        <h4><span className="text-white">{(hpData[0]!==undefined && hpData[0].attendanceTitle!==undefined) ? hpData[0].attendanceTitle : '' }</span></h4>
                    </div>
                    <a href={(hpData[0]!==undefined && hpData[0].attendanceLink!==undefined) ? hpData[0].attendanceLink : '' } target="blank" className="btn btn-block bg1 py-4 text-white">{(hpData[0]!==undefined && hpData[0].attendanceLinkText!==undefined) ? hpData[0].attendanceLinkText : '' }</a>
                </div>
            </div>
        </div>
    </section>
    <section className="wex-resources-section py-5">
        <div className="container">
        <div className="row"> 
            <div className="col-md-12">
            <h3 className="text-center mb-4 heading-title">{ hpData[0]!==undefined ? hpData[0].wexHeading : '' }</h3>
            <p className="text-center lead mb-5">{ hpData[0]!==undefined ? hpData[0].wexDesc : '' }</p>
            </div>
            <div className="col-md-6 mt-4">
            <div className="img-wrapper">
                <img src={ hpData[0]!==undefined ? hpData[0].wexImg1 : '' } alt="" className="img-fluid" />
            </div>
            <div className="resource-title text-center p-5 bg3">
                <h4><span className="text-white">{ hpData[0]!==undefined ? hpData[0].wexTitle1 : '' }</span></h4>
            </div>
            <a href={ hpData[0]!==undefined ? hpData[0].wexBtnLink1 : '' } target="blank" className="btn btn-block bg1 py-4 text-white">{ hpData[0]!==undefined ? hpData[0].wexBtn1 : '' }</a>
            </div>
            <div className="col-md-6 mt-4">
            <div className="img-wrapper">
                <img src={ hpData[0]!==undefined ? hpData[0].wexImg2 : '' } alt="" className="img-fluid" />
            </div>
            <div className="resource-title text-center p-5 bg3">
                <h4><span className="text-white">{ hpData[0]!==undefined ? hpData[0].wexTitle2 : '' }</span></h4>
            </div>
            <a href={ hpData[0]!==undefined ? hpData[0].wexBtnLink2 : '' } target="blank" className="btn btn-block bg1 py-4 text-white">{ hpData[0]!==undefined ? hpData[0].wexBtn2 : '' }</a>
            </div>
        </div>
        </div>
    </section>
    <section className="section-logos">
        <div className="container">
            <h4 class="lead mb-5 heading-title pb-5">
            Check Out Our Other Virtual Work Experience Programmes

            </h4>
        <div className="row">
            <a href={ hpData[0]!==undefined ? hpData[0].opLink1 : '' } target="blank" className="col">
            <img src={ hpData[0]!==undefined ? hpData[0].opImg1 : '' } alt="" className="img-fluid" />
            </a>
            <a href={ hpData[0]!==undefined ? hpData[0].opLink2 : '' } target="blank" className="col">
            <img src={ hpData[0]!==undefined ? hpData[0].opImg2 : '' } alt="" className="img-fluid" />
            </a>
            <a href={ hpData[0]!==undefined ? hpData[0].opLink3 : '' } target="blank" className="col">
            <img src={ hpData[0]!==undefined ? hpData[0].opImg3 : '' } alt="" className="img-fluid" />
            </a>
            <a href={ hpData[0]!==undefined ? hpData[0].opLink4 : '' } target="blank" className="col">
            <img src={ hpData[0]!==undefined ? hpData[0].opImg4 : '' } alt="" className="img-fluid" />
            </a> 
        </div>
        
        </div>
    </section>
    <section className="post-event-action py-5 bg-light">
        <div className="container">
        <div className="row"> 
            <div className="col-md-10 mx-auto">
            <h3 className="text-center mb-4 heading-title">{(hpData[0]!==undefined && hpData[0].heaTitle!==undefined) ? hpData[0].heaTitle : '' }</h3>
            <p className="mt-5 mb-5 text-center">
            {(hpData[0]!==undefined && hpData[0].heaDesc!==undefined) ? hpData[0].heaDesc : '' }
            </p>
            <div className="row">
            <div className="col-md-6 mt-4">
             <div className="bg2 p-5 text-center pea-block">
             <h3 className="heading-title">{(hpData[0]!==undefined && hpData[0].heaTitle1!==undefined) ? hpData[0].heaTitle1 : '' }</h3>
            <p className="my-5">
            {(hpData[0]!==undefined && hpData[0].heaDesc1!==undefined) ? hpData[0].heaDesc1 : '' }
            </p>
            <a href={(hpData[0]!==undefined && hpData[0].heaLink1!==undefined) ? hpData[0].heaLink1 : '' } target="blank" className="btn btn-block bg1 py-4 text-white text-uppercase">{(hpData[0]!==undefined && hpData[0].heaLinkTitle1!==undefined) ? hpData[0].heaLinkTitle1 : '' }</a>
             </div>
            </div>
            <div className="col-md-6 mt-4">
             <div className="bg2 p-5 text-center pea-block">
             <h3 className="heading-title">{(hpData[0]!==undefined && hpData[0].heaTitle2!==undefined) ? hpData[0].heaTitle2 : '' }</h3>
            <p className="my-5">
            {(hpData[0]!==undefined && hpData[0].heaDesc2!==undefined) ? hpData[0].heaDesc2 : '' }
            </p>
            <a href={(hpData[0]!==undefined && hpData[0].heaLink2!==undefined) ? hpData[0].heaLink2 : '' } target="blank" className="btn btn-block bg1 py-4 text-white text-uppercase">{(hpData[0]!==undefined && hpData[0].heaLinkTitle2!==undefined) ? hpData[0].heaLinkTitle2 : '' }</a>
             </div>
            </div>
            </div>
            </div>
          
        </div>
        </div>
    </section>
    {(hpData[0]!==undefined && hpData[0].hpHTML!==undefined) ? ReactHtmlParser(hpData[0].hpHTML) : '' }
    <section className="post-event-action py-5">
        <div className="container">
        <div className="row"> 
            <div className="col-md-10 mx-auto">
            <h3 className="text-center mb-4 heading-title">{(hpData[0]!==undefined && hpData[0].eaTitle!==undefined) ? hpData[0].eaTitle : '' }</h3>
            <p className="mt-5 mb-5 text-center">
            {(hpData[0]!==undefined && hpData[0].eaDesc!==undefined) ? hpData[0].eaDesc : '' }
            </p>
            <div className="row">
            <div className="col-md-6 mt-4">
             <div className="bg2 p-5 text-center pea-block">
             <h3 className="heading-title">{(hpData[0]!==undefined && hpData[0].eaTitle1!==undefined) ? hpData[0].eaTitle1 : '' }</h3>
            <p className="my-5">
            {(hpData[0]!==undefined && hpData[0].eaDesc1!==undefined) ? hpData[0].eaDesc1 : '' }
            </p>
            <a href={(hpData[0]!==undefined && hpData[0].eaLink1!==undefined) ? hpData[0].eaLink1 : '' } target="blank" className="btn btn-block bg1 py-4 text-white text-uppercase">{(hpData[0]!==undefined && hpData[0].eaLinkTitle1!==undefined) ? hpData[0].eaLinkTitle1 : '' }</a>
             </div>
            </div>
            <div className="col-md-6 mt-4">
             <div className="bg2 p-5 text-center pea-block">
             <h3 className="heading-title">{(hpData[0]!==undefined && hpData[0].eaTitle2!==undefined) ? hpData[0].eaTitle2 : '' }</h3>
            <p className="my-5">
            {(hpData[0]!==undefined && hpData[0].eaDesc2!==undefined) ? hpData[0].eaDesc2 : '' }
            </p>
            <a href={(hpData[0]!==undefined && hpData[0].eaLink2!==undefined) ? hpData[0].eaLink2 : '' } target="blank" className="btn btn-block bg1 py-4 text-white text-uppercase">{(hpData[0]!==undefined && hpData[0].eaLinkTitle2!==undefined) ? hpData[0].eaLinkTitle2 : '' }</a>
             </div>
            </div>
            </div>
            </div>
          
        </div>
        </div>
    </section>
    {(hpData[0]!==undefined && hpData[0].hpCTA!==undefined) ? ReactHtmlParser(hpData[0].hpCTA) : '' }
    <footer className="py-2 bg1 text-white text-center">
        <div className="container">
        {(hpData[0]!==undefined && hpData[0].footer!==undefined) ? hpData[0].footer : '' }
        </div>
    </footer>
    </div>
  );
};
export default Home;