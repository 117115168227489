import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyBHSWKq0G-nnlH8VDRO7VLwJfoggB5Cv_4",
    authDomain: "vetwex-7e4c5.firebaseapp.com",
    projectId: "vetwex-7e4c5",
    storageBucket: "vetwex-7e4c5.appspot.com",
    messagingSenderId: "934846231867",
    appId: "1:934846231867:web:5b7ee0de7d2baa4b1c331e"
  };

firebase.initializeApp(firebaseConfig);
export const auth = firebase.auth();
export const firedb = firebase.firestore();