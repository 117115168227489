import React, {useState, useEffect} from "react";
import { Link } from "@reach/router";
import { auth, firedb } from "../../firebase";

const Dashboard = (props) =>{
	const [value, setValue] = useState(null);
	const [docId, setDocId] = useState(null);

	const [hpData,setHpData]=useState([])

    const fetchHp=async()=>{
        const response=firedb.collection('homePage');
        const data=await response.get();
        data.docs.forEach(item=>{
         setHpData([...hpData,item.data()])
         setDocId(item.id)
        })

      }
      const hppData = [...hpData];
      const changeHandler = (event) => {
      	
          let nam = event.target.name;
          let val = event.target.value;
          hppData[0][nam] = val;
          setHpData([...hppData])
        }

        const submitHandler = () => {
        
        	firedb.collection('/homePage').doc(docId).update(hpData[0]).then(function(){
        		alert("success...");
        		
        	}).catch(function(error){
        		alert("error...");
        		console.log(error)

        	})
        }
    
      useEffect(() => {
        fetchHp();
      }, [])

	return( 
			(props.user.email=="rakasidebus@gmail.com" || props.user.email=="matt@mrolivers.co.uk") ? 
			<div className="container p-5">
				<div className="row">
					<div className="col-md-8 mx-auto">
					<p>Welcome, {props.user.email}</p>
					<div>
						<ul className="list-unstyled">
							<Link to="/">Back to home</Link>	
							<li><a href="#" onClick = {() => {auth.signOut()}}>Logout</a></li> 
						</ul>
					</div>
						<div className="form-group">
							<label htmlFor="">Video Embedd Code</label>
							<textarea name="videoCode" onChange={ changeHandler } value={ hpData[0]!==undefined ? hpData[0].videoCode : '' } id="" rows="3" placeholder="Video Embedd Code..." className="form-control"></textarea>
						</div>
						<div className="form-group">
							<label htmlFor="">Video Embedd Code (Alternative)</label>
							<textarea name="videoCode2" onChange={ changeHandler } value={ hpData[0]!==undefined ? hpData[0].videoCode2 : '' } id="" rows="3" placeholder="Video Embedd Code (Alternative)" className="form-control"></textarea>
						</div>
						<hr />
						<div className="form-group">
							<label htmlFor="">Slido Embedd Code</label>
							<textarea name="slidoCode" onChange={ changeHandler } value={ hpData[0]!==undefined ? hpData[0].slidoCode : '' } id="" rows="3" placeholder="Slido Embedd Code" className="form-control"></textarea>
						</div>
						<hr />
						<h3>Register Attendance</h3>
						<hr />
						<div className="form-group">
							<label>Section Heading</label>
							<input type="text" name="attendanceTitle" onChange={ changeHandler } value={ hpData[0]!==undefined ? hpData[0].attendanceTitle : '' } className="form-control" placeholder="section heading..."/>
						</div>
						<div className="form-group">
							<label>Section Description</label>
							<textarea rows="4" name="attendanceDesc" onChange={ changeHandler } value={ hpData[0]!==undefined ? hpData[0].attendanceDesc : '' } className="form-control" placeholder="section description..."></textarea>
						</div>
						<div className="form-group">
							<label>Image URL</label>
							<input type="text" name="attendanceImg" onChange={ changeHandler } value={ hpData[0]!==undefined ? hpData[0].attendanceImg : '' } className="form-control" placeholder="image URL..."/>
						</div>
						<div className="form-group">
							<label>Attendance Link</label>
							<input type="text" name="attendanceLink" onChange={ changeHandler } value={ hpData[0]!==undefined ? hpData[0].attendanceLink : '' } className="form-control" placeholder="Attendance Link..."/>
						</div>
						<div className="form-group">
							<label>Button Text</label>
							<input type="text" name="attendanceLinkText" onChange={ changeHandler } value={ hpData[0]!==undefined ? hpData[0].attendanceLinkText : '' } className="form-control" placeholder="Button Text..."/>
						</div>
						<hr />
						<h3>WEX Resources</h3>
						<hr />
						<div className="form-group">
							<label>Section Heading</label>
							<input type="text" name="wexHeading" onChange={ changeHandler } value={ hpData[0]!==undefined ? hpData[0].wexHeading : '' } className="form-control" placeholder="section heading..."/>
						</div>
						<div className="form-group">
							<label>Section Description</label>
							<textarea rows="4" name="wexDesc" onChange={ changeHandler } value={ hpData[0]!==undefined ? hpData[0].wexDesc : '' } className="form-control" placeholder="section description..."></textarea>
						</div>
						<div className="form-group">
							
							<div className="row">
								<div className="col-md-6">
									<label htmlFor="">image url</label>
									<input type="text" name="wexImg1" onChange={ changeHandler } value={ hpData[0]!==undefined ? hpData[0].wexImg1 : '' } className="form-control" placeholder="image url"/>
									<hr />
									<label htmlFor="">Title</label>
									<input type="text" name="wexTitle1" onChange={ changeHandler } value={ hpData[0]!==undefined ? hpData[0].wexTitle1 : '' } className="form-control" placeholder="title"/>
									<hr />
									<label htmlFor="">Button Link</label>
									<input type="text" name="wexBtnLink1" onChange={ changeHandler } value={ hpData[0]!==undefined ? hpData[0].wexBtnLink1 : '' } className="form-control" placeholder="Button Text"/>
									<hr />
									<label htmlFor="">Button Text</label>
									<input type="text" name="wexBtn1" onChange={ changeHandler } value={ hpData[0]!==undefined ? hpData[0].wexBtn1 : '' } className="form-control" placeholder="Button Text"/>
								</div>
								<div className="col-md-6">
									<label htmlFor="">image url</label>
									<input type="text" name="wexImg2" onChange={ changeHandler } value={ hpData[0]!==undefined ? hpData[0].wexImg2 : '' } className="form-control" placeholder="image url"/>
									<hr />
									<label htmlFor="">Title</label>
									<input type="text" name="wexTitle2" onChange={ changeHandler } value={ hpData[0]!==undefined ? hpData[0].wexTitle2 : '' } className="form-control" placeholder="title"/>
									<hr />
									<label htmlFor="">Button Link</label>
									<input type="text" name="wexBtnLink2" onChange={ changeHandler } value={ hpData[0]!==undefined ? hpData[0].wexBtnLink2 : '' } className="form-control" placeholder="Button Text"/>
									<hr />
									<label htmlFor="">Button Text</label>
									<input type="text" name="wexBtn2" onChange={ changeHandler } value={ hpData[0]!==undefined ? hpData[0].wexBtn2 : '' } className="form-control" placeholder="Button Text"/>
								</div>
							</div>
						</div>
						<hr />
						<div className="form-group">
							<h3>Other Programmes</h3>
							{<div className="row">
								<div className="col-md-3">
									<div className="form-group">
										<label>Image source 1</label>
										<input name="opImg1"  onChange={ changeHandler } value={ hpData[0]!==undefined ? hpData[0].opImg1 : '' } className="form-control" />
									</div>
									<div className="form-group">
										<label>Image Link 1</label>
										<input name="opLink1"  onChange={ changeHandler } value={ hpData[0]!==undefined ? hpData[0].opLink1 : '' } className="form-control" />
									</div>
								</div>
								<div className="col-md-3">
									<div className="form-group">
										<label>Image source 2</label>
										<input name="opImg2"  onChange={ changeHandler } value={ hpData[0]!==undefined ? hpData[0].opImg2 : '' } className="form-control" />
									</div>
									<div className="form-group">
										<label>Image Link 2</label>
										<input name="opLink2"  onChange={ changeHandler } value={ hpData[0]!==undefined ? hpData[0].opLink2 : '' } className="form-control" />
									</div>
								</div>
								<div className="col-md-3">
									<div className="form-group">
										<label>Image source 3</label>
										<input name="opImg3"  onChange={ changeHandler } value={ hpData[0]!==undefined ? hpData[0].opImg3 : '' } className="form-control" />
									</div>
									<div className="form-group">
										<label>Image Link 3</label>
										<input name="opLink3"  onChange={ changeHandler } value={ hpData[0]!==undefined ? hpData[0].opLink3 : '' } className="form-control" />
									</div>
								</div>
								<div className="col-md-3">
									<div className="form-group">
										<label>Image source 4</label>
										<input name="opImg4"  onChange={ changeHandler } value={ hpData[0]!==undefined ? hpData[0].opImg4 : '' } className="form-control" />
									</div>
									<div className="form-group">
										<label>Image Link 4</label>
										<input name="opLink4"  onChange={ changeHandler } value={ hpData[0]!==undefined ? hpData[0].opLink4 : '' } className="form-control" />
									</div>
								</div>
							</div>}
						</div>
						<hr />
						<h3>Homework</h3>
						<hr />
						<div className="form-group">
							<label>Section Heading</label>
							<input type="text" name="heaTitle" onChange={ changeHandler } value={ hpData[0]!==undefined ? hpData[0].heaTitle : '' } className="form-control" placeholder="section heading..."/>
						</div>
						<div className="form-group">
							<label>Section Description</label>
							<textarea rows="4" name="heaDesc" onChange={ changeHandler } value={ hpData[0]!==undefined ? hpData[0].heaDesc : '' } className="form-control" placeholder="section description..."></textarea>
						</div>
						<div className="form-group">
							
							<div className="row">
								<div className="col-md-6">
									<label htmlFor="">Title</label>
									<input type="text" name="heaTitle1" onChange={ changeHandler } value={ hpData[0]!==undefined ? hpData[0].heaTitle1 : '' } className="form-control" placeholder="title"/>
									<hr />
									<label htmlFor="">Description</label>
									<textarea rows="4" name="heaDesc1" onChange={ changeHandler } value={ hpData[0]!==undefined ? hpData[0].heaDesc1 : '' } className="form-control" placeholder="description..."></textarea>
									<hr />
									<label htmlFor="">Button Link</label>
									<input type="text" name="heaLink1" onChange={ changeHandler } value={ hpData[0]!==undefined ? hpData[0].heaLink1 : '' } className="form-control" placeholder="Button Link"/>
									<hr />
									<label htmlFor="">Button Text</label>
									<input type="text" name="heaLinkTitle1" onChange={ changeHandler } value={ hpData[0]!==undefined ? hpData[0].heaLinkTitle1 : '' } className="form-control" placeholder="Button Text"/>
								</div>
								<div className="col-md-6">
								<label htmlFor="">Title</label>
									<input type="text" name="heaTitle2" onChange={ changeHandler } value={ hpData[0]!==undefined ? hpData[0].heaTitle2 : '' } className="form-control" placeholder="title"/>
									<hr />
									<label htmlFor="">Description</label>
									<textarea rows="4" name="heaDesc2" onChange={ changeHandler } value={ hpData[0]!==undefined ? hpData[0].heaDesc2 : '' } className="form-control" placeholder="description..."></textarea>
									<hr />
									<label htmlFor="">Button Link</label>
									<input type="text" name="heaLink2" onChange={ changeHandler } value={ hpData[0]!==undefined ? hpData[0].heaLink2 : '' } className="form-control" placeholder="Button Link"/>
									<hr />
									<label htmlFor="">Button Text</label>
									<input type="text" name="heaLinkTitle2" onChange={ changeHandler } value={ hpData[0]!==undefined ? hpData[0].heaLinkTitle2 : '' } className="form-control" placeholder="Button Text"/>
								</div>
							</div>
						</div>
						<div className="form-group">
							<h3>WEX Announcements</h3>
							<textarea name="hpHTML" rows="12" onChange={ changeHandler } value={ hpData[0]!==undefined ? hpData[0].hpHTML : '' } className="form-control" placeholder="custom HTML"></textarea>
						</div>
						<hr />
						<hr />
						<h3>Event Actions</h3>
						<hr />
						<div className="form-group">
							<label>Section Heading</label>
							<input type="text" name="eaTitle" onChange={ changeHandler } value={ hpData[0]!==undefined ? hpData[0].eaTitle : '' } className="form-control" placeholder="section heading..."/>
						</div>
						<div className="form-group">
							<label>Section Description</label>
							<textarea rows="4" name="eaDesc" onChange={ changeHandler } value={ hpData[0]!==undefined ? hpData[0].eaDesc : '' } className="form-control" placeholder="section description..."></textarea>
						</div>
						<div className="form-group">
							
							<div className="row">
								<div className="col-md-6">
									<label htmlFor="">Title</label>
									<input type="text" name="eaTitle1" onChange={ changeHandler } value={ hpData[0]!==undefined ? hpData[0].eaTitle1 : '' } className="form-control" placeholder="title"/>
									<hr />
									<label htmlFor="">Description</label>
									<textarea rows="4" name="eaDesc1" onChange={ changeHandler } value={ hpData[0]!==undefined ? hpData[0].eaDesc1 : '' } className="form-control" placeholder="description..."></textarea>
									<hr />
									<label htmlFor="">Button Link</label>
									<input type="text" name="eaLink1" onChange={ changeHandler } value={ hpData[0]!==undefined ? hpData[0].eaLink1 : '' } className="form-control" placeholder="Button Link"/>
									<hr />
									<label htmlFor="">Button Text</label>
									<input type="text" name="eaLinkTitle1" onChange={ changeHandler } value={ hpData[0]!==undefined ? hpData[0].eaLinkTitle1 : '' } className="form-control" placeholder="Button Text"/>
								</div>
								<div className="col-md-6">
								<label htmlFor="">Title</label>
									<input type="text" name="eaTitle2" onChange={ changeHandler } value={ hpData[0]!==undefined ? hpData[0].eaTitle2 : '' } className="form-control" placeholder="title"/>
									<hr />
									<label htmlFor="">Description</label>
									<textarea rows="4" name="eaDesc2" onChange={ changeHandler } value={ hpData[0]!==undefined ? hpData[0].eaDesc2 : '' } className="form-control" placeholder="description..."></textarea>
									<hr />
									<label htmlFor="">Button Link</label>
									<input type="text" name="eaLink2" onChange={ changeHandler } value={ hpData[0]!==undefined ? hpData[0].eaLink2 : '' } className="form-control" placeholder="Button Link"/>
									<hr />
									<label htmlFor="">Button Text</label>
									<input type="text" name="eaLinkTitle2" onChange={ changeHandler } value={ hpData[0]!==undefined ? hpData[0].eaLinkTitle2 : '' } className="form-control" placeholder="Button Text"/>
								</div>
							</div>
						</div>
						<hr />
						<div className="form-group">
							<h3>CTA Section</h3>
							<textarea name="hpCTA" rows="12" onChange={ changeHandler } value={ hpData[0]!==undefined ? hpData[0].hpCTA : '' } className="form-control" placeholder="custom HTML"></textarea>
						</div>
						<hr />
						<h3>Footer</h3>
						<hr />
						<div className="form-group">
							<label>Footer Text</label>
							<input type="text" name="footer" onChange={ changeHandler } value={ hpData[0]!==undefined ? hpData[0].footer : '' } className="form-control" placeholder="footer text..."/>
						</div>
						<div className="form-group">
							<button className="btn btn-primary btn-block btn-lg" onClick={ submitHandler }>Save</button>
						</div>
					</div>
				</div>
			</div> 
			: 
			<div className="d-flex justify-content-center align-items-center text-center text-danger" style={{ height:100+'vh' }}><div><h1>Permission Denied...</h1><p>You are not allowed to view this page</p></div></div>
		)
};

export default Dashboard;